
import '../style/index-banner.scss';

import * as THREE from 'three';
import { Text } from 'troika-three-text';
import {
  SMAAEffect, SelectiveBloomEffect, OutlineEffect, DepthOfFieldEffect, EffectComposer, EffectPass, RenderPass, SMAAPreset, EdgeDetectionMode, BlendFunction, KernelSize,
} from 'postprocessing';
import Hammer from 'hammerjs';

const awardsRawData = require('../static/awards.json')

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


const container = document.querySelector('.three-container');
let containerHalfX = container.offsetWidth / 2;
let containerHalfY = container.offsetHeight / 2;

let mouse;
const isMouseDown = false;

// let stats;
let camera;
let scene;
let renderer;
let raycaster;
let composer;
let selectiveBloomEffect;
let outlineEffect;
let depthOfFieldEffect;
let selectedObject;
const selectedObjects = [];
const rayCasterObjects = [];
let timeCylinder;
let plane;
let timeCylinderHeight = 40;
const decoGroup = new THREE.Group();
const yearGroup = {};
const textGroup = new THREE.Group();
const renderGroup = new THREE.Group();
let time = 0;
let isSelectYear = false;


let selectedYear = null;

const originTextConfig = {
  color: 0x000b1c,
  fontSize: 8,
  outlineWidth: '2%',
};

const cameraPosition = {
  x: 0,
  y: -40,
  z: 300,
  degree: 0,
};

const cameraRotateDegree = 37;


function getSizeByDistance(z) {
  const { position, fov, aspect } = camera;
  const distance = position.distanceTo(new THREE.Vector3(0, 0, z)) * Math.sign(position.z);
  const vFov = THREE.Math.degToRad(fov);
  const height = 2 * Math.tan(vFov / 2) * distance;
  const width = height * aspect;

  return { width, height };
}

function onWindowResize() {
  containerHalfX = container.offsetWidth / 2;
  containerHalfY = container.offsetHeight / 2;

  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();

  const { width, height } = getSizeByDistance(-1000);
  plane.scale.set(width, height, 1);

  renderer.setSize(container.offsetWidth, container.offsetHeight);
}

function handleClickCube(selectedObject) {
  if (selectedObject.name !== '2022 || 台新藝術獎') {
    openAwardDialog(selectedObject.name);
  } else {
    container.querySelector('.three-container__continue').classList.add('-show');
  }
}

function handleClick(event) {
  event.preventDefault();

  mouse.x = ((isMobile ? event.srcEvent.layerX : event.layerX) / container.offsetWidth) * 2 - 1;
  mouse.y = -((isMobile ? event.srcEvent.layerY : event.layerY) / container.offsetHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObjects(rayCasterObjects, true);

  const { selection } = outlineEffect;
  if (intersects.length > 0) {
    const { object } = intersects[0];

    if (object.isText === true) {
      selection.clear();

      textGroup.children.forEach((textMesh) => {
        textMesh.fontSize = originTextConfig.fontSize;
        textMesh.outlineWidth = originTextConfig.outlineWidth;
        textMesh.color = originTextConfig.color;
        textMesh.outlineColor = originTextConfig.color;
      });
      if (selectedYear !== object.yearKey) {
        selectedYear = object.yearKey;
        object.fontSize = 11;
        object.outlineWidth = '3%';
        object.color = 0x000000;
        object.outlineColor = 0x000000;

        yearGroup[object.yearKey.toString()].children.forEach((shapeObject) => {
          selection.add(shapeObject);
        });
      } else {
        selectedYear = null;
      }
    } else if (object !== undefined) {
      selectedObject = object;
      if (selection.has(selectedObject)) {
          handleClickCube(selectedObject)
      } else {
        selection.clear();
        selectedYear = null;
        textGroup.children.forEach((textMesh) => {
          textMesh.fontSize = originTextConfig.fontSize;
          textMesh.outlineWidth = originTextConfig.outlineWidth;
          textMesh.color = originTextConfig.color;
          textMesh.outlineColor = originTextConfig.color;
        });
      }
    }
  } else {
    textGroup.children.forEach((textMesh) => {
      textMesh.fontSize = originTextConfig.fontSize;
      textMesh.outlineWidth = originTextConfig.outlineWidth;
      textMesh.color = originTextConfig.color;
      textMesh.outlineColor = originTextConfig.color;
    });
    selection.clear();
    selectedYear = null;
  }
}

function onDocumentMouseMove(event) {
  event.preventDefault();

  var mouse = new THREE.Vector2();
  mouse.x = ( event.layerX / container.offsetWidth ) * 2 - 1;
  mouse.y = - ( event.layerY / container.offsetHeight ) * 2 + 1;

  raycaster.setFromCamera( mouse, camera );
  const { selection } = outlineEffect;
  if (selection.size > 0 ) {
    const intersects = raycaster.intersectObjects(rayCasterObjects, true);
    if (intersects.length > 0) {
      const { object } = intersects[0];
      container.style.cursor = selection.has(object) ? 'pointer' : 'initial';
    } else {
      container.style.cursor = 'initial';
    }
  }
   else {
    const intersects = raycaster.intersectObjects(rayCasterObjects.filter((item) => item.isText), true);
    container.style.cursor = intersects.length > 0 ? 'pointer' : 'initial';
  }
}

function handleWheel(e) {
  e.preventDefault();
  const gap = e.wheelDeltaY / 30;
  const topY = -40;
  const bottomY = timeCylinderHeight * -1 + 30;
  if (
    (cameraPosition.y < topY && cameraPosition.y > bottomY)
    || e.wheelDeltaY > 0 && cameraPosition.y <= bottomY
    || e.wheelDeltaY < 0 && cameraPosition.y >= topY
  ) {
    cameraPosition.y += gap;
    camera.position.y = cameraPosition.y;
    camera.lookAt(new THREE.Vector3(0, cameraPosition.y, 0));
  }
  camera.rotation.z = cameraRotateDegree * THREE.Math.DEG2RAD;
  plane.position.y = camera.position.y;
}

function handlePan(event) {
  cameraPosition.degree += 360 * (event.velocityX * 10 / container.offsetWidth);

  if (['panup', 'pandown'].includes(event.additionalEvent)) {
    const gap = 5 * (event.deltaY > 0 ? 1 : -1);
    const topY = -40;
    const bottomY = timeCylinderHeight * -1 + 30;
    if (
      (cameraPosition.y < topY && cameraPosition.y > bottomY)
      || event.deltaY > 0 && cameraPosition.y <= bottomY
      || event.deltaY < 0 && cameraPosition.y >= topY
    ) {
      cameraPosition.y += gap;
      camera.position.y = cameraPosition.y;
      camera.lookAt(new THREE.Vector3(0, cameraPosition.y, 0));
    }
    camera.rotation.z = cameraRotateDegree * THREE.Math.DEG2RAD;
    plane.position.y = camera.position.y;
  }
}

function render() {
  time += 1;
  renderGroup.rotation.y = cameraPosition.degree * THREE.Math.DEG2RAD;
  camera.lookAt(new THREE.Vector3(0, cameraPosition.y, 0));
  camera.rotation.z = cameraRotateDegree * THREE.Math.DEG2RAD;
  plane.position.y = camera.position.y;

  const floatYOffset = Math.sin(Math.PI / 180 * time) * 0.05;
  renderGroup.position.y += floatYOffset;
}

function init() {
  // ====================== main ======================
  scene = new THREE.Scene();

  // ===== render =====
  renderer = new THREE.WebGLRenderer({
    powerPreference: 'high-performance',
    antialias: true,
    stencil: false,
    depth: false,
  });
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  container.appendChild(renderer.domElement);

  // ===== camera =====
  camera = new THREE.PerspectiveCamera(45, container.offsetWidth / container.offsetHeight, 0.1, 3000);
  camera.position.x = cameraPosition.x;
  camera.position.y = cameraPosition.y;
  camera.position.z = cameraPosition.z;
  camera.lookAt(new THREE.Vector3(0, 0, 0));

  // ===== postprocessing =====
  composer = new EffectComposer(renderer);
  composer.addPass(new RenderPass(scene, camera));

  const smaaEffect = new SMAAEffect({
    preset: SMAAPreset.HIGH,
    edgeDetectionMode: EdgeDetectionMode.COLOR,
  });
  const smaaPass = new EffectPass(camera, smaaEffect);

  selectiveBloomEffect = new SelectiveBloomEffect(scene, camera, {
    blendFunction: BlendFunction.SCREEN,
    kernelSize: KernelSize.MEDIUM,
    luminanceThreshold: 0,
    luminanceSmoothing: 0.5,
    height: 240,
    intensity: 1,
  });
  selectiveBloomEffect.inverted = true;
  const bloomPass = new EffectPass(camera, selectiveBloomEffect);

  // 物件發光
  outlineEffect = new OutlineEffect(scene, camera, {
    blendFunction: BlendFunction.SCREEN,
    edgeStrength: 20,
    visibleEdgeColor: 0xffffff,
    hiddenEdgeColor: 0xffffff00,
    height: 1080,
    blur: true,
    xRay: false,
  });
  outlineEffect.blurPass.kernelSize = 3;
  outlineEffect.selection.set(selectedObjects);
  const outlinePass = new EffectPass(camera, outlineEffect);

  // 物件模糊
  depthOfFieldEffect = new DepthOfFieldEffect(camera, {
    focusDistance: 1,
    // focusRange: 0,
    bokehScale: 2.0,
    height: 480,
  });
  const cocMaterial = depthOfFieldEffect.circleOfConfusionMaterial;
  cocMaterial.uniforms.focusDistance.value = 0;
  cocMaterial.uniforms.focalLength.value = 0.4;
  const depthOfFieldPass = new EffectPass(
    camera,
    depthOfFieldEffect,
  );

  composer.addPass(smaaPass);
  composer.addPass(outlinePass);
  composer.addPass(bloomPass);
  composer.addPass(depthOfFieldPass);

  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();

  // ===== light =====
  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(ambientLight);

  const directionLight = new THREE.DirectionalLight(0xffffff, 3);
  directionLight.position.set(0, 1, 1);
  scene.add(directionLight);

  // ====================== main ======================

  // ===== float items =====
  const geometryRenderPosition = {
    x: -120,
    y: -30,
  };

  const materialBall = new THREE.MeshStandardMaterial({
    color: 0x080808,
    envMapIntensity: 1,
    metalness: 0.7,
    roughness: 0.1,
  });
  const envTexture = new THREE.TextureLoader()
    .load(
      '../static/images/texture.jpg',
      (texture) => {
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        const exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
        const exrBackground = exrCubeRenderTarget.texture;
        const envMap = exrCubeRenderTarget ? exrCubeRenderTarget.texture : null;
        scene.environment = exrBackground;
        materialBall.envMap = envMap;
        materialBall.needsUpdate = true;
      },
    );

  function generateGeometry(type, position, clickable = false, sizeScale = 1, name = null, targetGroup = null) {
    let geometry = null;
    const baseSize = (Math.floor(Math.random() * 15) + 15) * sizeScale;
    switch (type) {
      case 'ball':
        geometry = new THREE.SphereGeometry(baseSize, 32, 32);
        break;
      case 'cone':
        geometry = new THREE.ConeGeometry(baseSize, baseSize * 2, 50);
        break;
      case 'cylinder':
        geometry = new THREE.CylinderGeometry(baseSize, baseSize, baseSize * 2, 48);
        break;
      default:
        break;
    }

    if (!geometry) {
      console.error('generate geometry type error');
      return;
    }

    const shape = new THREE.Mesh(geometry, materialBall);
    shape.position.x = position.x || 0;
    shape.position.y = position.y || 0;
    shape.position.z = position.z || 0;
    shape.rotation.x = Math.floor(Math.random() * 1000) / 100;
    shape.rotation.y = Math.floor(Math.random() * 1000) / 100;
    shape.rotation.z = Math.floor(Math.random() * 1000) / 100;
    shape.name = name;

    if (targetGroup) {
      targetGroup.add(shape);
    }

    if (clickable) {
      rayCasterObjects.push(shape);
    }
    selectiveBloomEffect.selection.add(shape);
  }

  // ==== 主要物件 =====
  awardsRawData.forEach((yearData) => {
    yearGroup[yearData.year] = new THREE.Group();
    for (let index = 0; index < yearData.projects.length; index++) {
      const PER_DEGREE = (Math.PI * 2) / yearData.projects.length;
      const CURRENT_DEGREE = PER_DEGREE * index;
      const randomOffsetDegree = PER_DEGREE * Math.random() * 0.5;
      generateGeometry(
        ['ball', 'cone', 'cylinder'][Math.floor(Math.random() * 3)],
        {
          x: Math.cos(CURRENT_DEGREE + randomOffsetDegree) * (isMobile ? 60 : 100),
          y: geometryRenderPosition.y + Math.floor(Math.random() * 10) * (Math.random() < 0.5 ? -1 : 1),
          z: Math.sin(CURRENT_DEGREE + randomOffsetDegree) * (isMobile ? 60 : 100),
        },
        true,
        isMobile ? 0.6 : 1,
        `${yearData.year} || ${yearData.projects[index]}`,
        yearGroup[yearData.year],
      );
    }
    // Create Text
    const yearText = new Text();

    yearText.text = yearData.year;
    yearText.fontSize = originTextConfig.fontSize;
    yearText.position.x = 0;
    yearText.position.z = 47;
    yearText.position.y = geometryRenderPosition.y;
    yearText.color = originTextConfig.color;
    yearText.outlineWidth = originTextConfig.outlineWidth;
    yearText.outlineColor = originTextConfig.color;
    yearText.anchorX = 'center';
    yearText.anchorY = 'middle';
    yearText.isText = true;
    yearText.yearKey = yearData.year;
    rayCasterObjects.push(yearText);
    textGroup.add(yearText);

    const yearGap = isMobile ? 30 : 60;
    timeCylinderHeight += yearGap;
    geometryRenderPosition.y -= yearGap;
  });

  // ===== 裝飾物 =====
  const groupHeight = 80;
  const groupCounts = timeCylinderHeight / groupHeight;
  for (let count = 0; count < groupCounts; count++) {
    const baseRadius = 450 * (Math.sin(100) * 0.08 + 1);
    const decoPerGroup = Math.floor(Math.random() * (isMobile ? 3 : 7)) + 3;
    const PER_DEGREE = (Math.PI * 2) / decoPerGroup;
    const randomOffsetDegree = PER_DEGREE * Math.random() * 0.5;
    for (let b = 0; b < decoPerGroup; b++) {
      const CURRENT_DEGREE = PER_DEGREE * b;
      generateGeometry(
        ['ball', 'cone', 'cylinder'][Math.floor(Math.random() * 3)],
        {
          x: Math.cos(CURRENT_DEGREE + randomOffsetDegree) * baseRadius,
          y: count * groupHeight * -1 + Math.floor(Math.random() * 30) * (Math.random() < 0.5 ? -1 : 1),
          z: Math.sin(CURRENT_DEGREE + randomOffsetDegree - Math.random() * (Math.random() ? 1 : -1)) * baseRadius,
        },
        false,
        isMobile ? 0.6 : 0.85,
        null,
        decoGroup,
      );
    }
  }
  // ===== 加入物件至 scene =====
  scene.add(textGroup);

  renderGroup.add(decoGroup);
  Object.keys(yearGroup).forEach((key) => {
    renderGroup.add(yearGroup[key]);
  });
  scene.add(renderGroup);

  // ===== 中央柱體 ====
  const barWidth = isMobile ? 20 : 26;
  const extraLength = 300;
  let geometry = new THREE.CylinderGeometry(barWidth, barWidth, timeCylinderHeight + extraLength, 48);
  let material = new THREE.MeshPhysicalMaterial({
    roughness: 0.5,
    transmission: 0.85,
    thickness: 5,
    ior: 1,
    reflectivity: 0.15,
  });
  material.onBeforeCompile = (shader) => {
    shader.vertexShader = shader.vertexShader.replace(
      'varying vec3 vViewPosition;',
      `
        varying vec3 vViewPosition;
        varying vec2 vUv;
      `,
    );
    shader.vertexShader = shader.vertexShader.replace(
      'void main() {',
      `
        void main() {
          vUv = uv;
      `,
    );
    shader.fragmentShader = shader.fragmentShader.replace(
      'varying vec3 vViewPosition;',
      `
        varying vec3 vViewPosition;
        varying vec2 vUv;
      `,
    );
    shader.fragmentShader = shader.fragmentShader.replace(
      '#include <dithering_fragment>',
      `
        #include <dithering_fragment>
        float intensity = 1.02 - dot(vNormal, vec3(0., 0., 1.));
        vec3 atomSphere = vec3(1.0) * pow(intensity, 1.5) * 0.7;
        gl_FragColor.rgb += atomSphere;
      `,
    );
    return shader;
  };
  timeCylinder = new THREE.Mesh(geometry, material);
  timeCylinder.position.y = timeCylinderHeight / 2 * -1;
  scene.add(timeCylinder);

  // ===== 漸層背景 =====
  geometry = new THREE.PlaneBufferGeometry(1, 1);
  material = new THREE.ShaderMaterial({
    uniforms: {},
    vertexShader: `
        varying vec2 vUv;
        void main(){
          vec3 pos = position;
          vec4 modelPosition = modelMatrix * vec4(pos, 1.);
          vec4 modelViewPosition = viewMatrix * modelPosition;
          vec4 projectionPosition = projectionMatrix * modelViewPosition;

          gl_Position = projectionPosition;

          vUv = uv;
        }
      `,

    fragmentShader: `
        varying vec2 vUv;
        void main(){
          vec2 uv = vUv;
          float final = pow(1. - distance(uv, vec2(0.5)), 3.);
          vec3 color = mix(
            vec3(57. / 255., 95. / 255., 120. / 255.),
            vec3(215. / 255., 224. / 255., 226. / 255.),
            final
          );
          gl_FragColor = vec4(color, 1.);
        }
      `,
    side: THREE.FrontSide,
  });
  plane = new THREE.Mesh(geometry, material);
  const { width, height } = getSizeByDistance(-1000);
  plane.position.z = -1000;
  plane.rotation.z = cameraRotateDegree * THREE.Math.DEG2RAD;
  plane.scale.set(width, height, 1);
  scene.add(plane);
  selectiveBloomEffect.selection.add(plane);

  const mc = new Hammer.Manager(container);
  const Pan = new Hammer.Pan();
  mc.add(Pan);
  mc.on('pan', handlePan);
  if (!isMobile) {
    // 桌機事件
    container.addEventListener('wheel', handleWheel);
    renderer.domElement.addEventListener('pointerdown', handleClick, false);
    window.addEventListener('mousemove', onDocumentMouseMove)
  } else {
    // 手機事件
    const Tap = new Hammer.Tap();
    mc.add(Tap);
    mc.on('pan', handlePan);
    mc.on('tap', handleClick);
  }
  window.addEventListener('resize', onWindowResize);
}

function animate() {
  requestAnimationFrame(animate);

  composer.render();
  render();

  // stats.update();
}

init();
animate();

// ===== rest =====
document.querySelector('.three-container__continue-icon').addEventListener('click', (e) => {
  e.stopPropagation();
  container.querySelector('.three-container__continue').classList.remove('-show');

  cameraPosition.y = -40;
  camera.position.y = -40;
  camera.lookAt(new THREE.Vector3(0, -40, 0));
});

// ===== close guide =====
document.querySelector('.three-container__guide-close').addEventListener('click', (e) => {
  e.stopPropagation();
  container.querySelector('.three-container__guide').classList.add('-hide');
});

// ===== loading =====
const loadingElm = document.querySelector('.index-loading');
window.addEventListener('load', () => {
  loadingElm.classList.add('-loaded');
  setTimeout(() => {
    loadingElm.classList.add('-hide');
    document.querySelector('.three-container__guide').classList.add('-show');
  }, 2800);
});
